import request from '@/utils/request'; // /user/myInformation我的资料

export var myInformation = function myInformation(data) {
  return request({
    method: 'post',
    url: '/user/myInformation',
    data: data
  });
}; // /identityInfo/get获取身份信息

export var get = function get(data) {
  return request({
    method: 'post',
    url: '/identityInfo/get',
    data: data
  });
}; // /identityInfo/save保存身份信息

export var save = function save(data) {
  return request({
    method: 'post',
    url: '/identityInfo/save',
    data: data
  });
}; // /information/get获取资料信息

export var informationget = function informationget(data) {
  return request({
    method: 'post',
    url: '/information/get',
    data: data
  });
}; // /information/relationsList亲属关系列表

export var relationsList = function relationsList(data) {
  return request({
    method: 'post',
    url: '/information/relationsList',
    data: data
  });
}; // /information/save保存资料信息

export var saveinformation = function saveinformation(data) {
  return request({
    method: 'post',
    url: '/information/save',
    data: data
  });
}; // /bank/get获取银行卡

export var getbank = function getbank(data) {
  return request({
    method: 'post',
    url: '/bank/get',
    data: data
  });
}; // /bank/getBankNameList获取银行名称列表

export var getBankNameList = function getBankNameList(data) {
  return request({
    method: 'post',
    url: '/bank/getBankNameList',
    data: data
  });
}; // /loan/myLoan我的贷款

export var myLoan = function myLoan(data) {
  return request({
    method: 'post',
    url: '/loan/myLoan',
    data: data
  });
}; // /loan/details贷款详情

export var details = function details(data) {
  return request({
    method: 'post',
    url: '/loan/details',
    params: data
  });
}; // /bank/addBank添加银行卡

export var addBank = function addBank(data) {
  return request({
    method: 'post',
    url: '/bank/addBank',
    data: data
  });
}; // /identityInfo/saveProofIncome保存收入证明

export var saveProofIncome = function saveProofIncome(data) {
  return request({
    method: 'post',
    url: '/identityInfo/saveProofIncome',
    data: data
  });
}; // /withdrawal/myWithdrawal我的提现

export var getmyWithdrawal = function getmyWithdrawal(data) {
  return request({
    method: 'post',
    url: '/withdrawal/myWithdrawal',
    params: data
  });
}; // /user/vip购买vip

export var vip = function vip(data) {
  return request({
    method: 'post',
    url: '/user/vip',
    params: data
  });
}; // /user/logOut退出登录

export var logOut = function logOut(data) {
  return request({
    method: 'post',
    url: '/user/logOut',
    params: data
  });
}; // /identityInfo/getProofIncome获取收入证明

export var getProofIncome = function getProofIncome(data) {
  return request({
    method: 'post',
    url: '/identityInfo/getProofIncome',
    params: data
  });
}; // /withdrawal/withdrawalDetails

export var withdrawalDetails = function withdrawalDetails(data) {
  return request({
    method: 'post',
    url: '/withdrawal/withdrawalDetails',
    params: data
  });
}; // /user/myInfo我的信息

export var myInfo = function myInfo(data) {
  return request({
    method: 'post',
    url: '/user/myInfo',
    data: data
  });
}; // /user/unreadInformation我的未读信息

export var getunreadInformation = function getunreadInformation(data) {
  return request({
    method: 'post',
    url: '/user/unreadInformation',
    data: data
  });
}; // /user/changePassword

export var changePassword = function changePassword(data) {
  return request({
    method: 'post',
    url: '/user/changePassword',
    params: data
  });
}; // /contact/getContactConfig

export var getContactConfig = function getContactConfig(data) {
  return request({
    method: 'post',
    url: '/contact/getContactConfig',
    params: data
  });
};
export var getLineUrl = function getLineUrl(data) {
  return request({
    method: 'post',
    url: '/contact/getLineUrl',
    params: data
  });
}; // /loan/repaymentList

export var repaymentList = function repaymentList(data) {
  return request({
    method: 'post',
    url: '/loan/repaymentList',
    params: data
  });
}; // 上传签名

export var autograph = function autograph(data) {
  return request({
    method: 'post',
    url: '/information/autograph',
    params: data
  });
}; // 获取签名

export var getAutograph = function getAutograph(data) {
  return request({
    method: 'post',
    url: '/information/getAutograph',
    params: data
  });
}; // 获取贷款协议信息-新

export var getLoanAgreementInfo = function getLoanAgreementInfo(data) {
  return request({
    method: 'post',
    url: '/loan/getLoanAgreementInfo',
    params: data
  });
};